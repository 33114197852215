import Fullpage from 'fullpage.js';
import first from './../../screens/first/first';
import second from './../../screens/second/second';

var fullpageInstance;
var smallWidth = 728;
var fullPageState = false;

var scrollControll = {
  init() {
    if(document.body.clientWidth > smallWidth) {
      scrollControll.fullPage();
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1000);
    }

    window.addEventListener('resize', () => {
      if(document.body.clientWidth <= smallWidth) {
        if(fullPageState == true) {
          scrollControll.exitFullPage();
          
          setTimeout(() => {
            window.scrollTo(0,0);
          }, 1000);
  
        }
      } else {
        if(fullPageState == false) {
          scrollControll.fullPage();
        }
      }
    });

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  },

  fullPage() {
    fullPageState = true;

    fullpageInstance = new Fullpage('.screens', {
      sectionSelector: '.screen',
      autoScrolling:true,
      scrollHorizontally: true,
      scrollingSpeed: 1000,
      menu: '.screenNavigation',
      licenseKey: '8079398A-ED364756-85848DC6-E60B7DE9',

      anchors:[
        '1',
        '2',
        '3',
        '4',
      ],

      onLeave: (origin, destination, direction) => {
        scrollControll.setCurrentSectionClass(origin, destination, direction);
        document.body.dataset.from = origin.anchor;

        if(destination.anchor === '1') {
          first.initSlider();
        }

        if(origin.anchor === '1') {
          first.stopSlider();
        }

        if(origin.anchor === '2') {
          second.pauseVideo();
        }
      },
    });
  },

  exitFullPage() {
    fullPageState = false;
    fullpageInstance.destroy('all');
  },

  setCurrentSectionClass(origin, destination, direction) {
    var currentScreen = destination.anchor;
    document.body.dataset.direction = direction;

    setTimeout(() => {
      document.body.dataset.currentScreen = currentScreen;
    }, 700);

    setTimeout(() => {
      document.body.dataset.direction = '';
    }, 350);
  },
};

export default scrollControll;