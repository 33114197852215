const videoNode = document.querySelector('.video');
const videoWrapperNode = document.querySelector('.videoWrapper');
const videoButtonNode = document.querySelector('.videoButton');
let mouseMoveTimeout;

const second = {
  videoPlayed: false,
  mouseMovedPeriodClass: false,

  init() {
    second.setListeners();
  },

  setListeners () {
    videoWrapperNode.addEventListener('click', second.playOrPause);

    videoNode.addEventListener('mousemove', () => {
      if(mouseMoveTimeout) {
        clearTimeout(mouseMoveTimeout);
      };

      if(!second.mouseMovedPeriodClass) {
        videoWrapperNode.classList.add('mouseMoved');
        second.mouseMovedPeriodClass = true;
      }

      mouseMoveTimeout = setTimeout(() => {
        videoWrapperNode.classList.remove('mouseMoved');
        second.mouseMovedPeriodClass = false;
      }, 500);
    });

    videoNode.addEventListener('ended', () => {
      videoWrapperNode.classList.add('videoEnded');
      videoButtonNode.innerHTML = 'Смотреть сначала';
      second.videoPlayed = false;
    }); 
  },

  playOrPause() {
    if(second.videoPlayed) {
      second.pauseVideo();
    } else {
      second.playVideo();
    }
  },

  pauseVideo() {
    videoNode.pause();
    videoWrapperNode.classList.remove('played');
    second.videoPlayed = false;

    videoButtonNode.innerHTML = 'Смотреть';
  },

  playVideo() {
    videoNode.play();
    videoWrapperNode.classList.add('played');
    videoWrapperNode.classList.remove('videoEnded');
    second.videoPlayed = true;

    videoButtonNode.innerHTML = 'Пауза';
  }
};

export default second;