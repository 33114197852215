const screenSlideNodes = document.querySelectorAll('.screenSlide');
const slideProgressNode = document.querySelector('.slideProgress');
const slideDuration = 7000;

var first = {
  currentSlide: 0,
  currentSlideTime: 0,
  sliderInterval: null,
  sliderProgressRAF: null,
  sliderCurrentProgress: 0,
  hideTimeout: null,

  init() {
    first.initSlider();
  },

  initSlider() {
    first.sliderInterval = setInterval(() => {
      first.currentSlideTime += 16,666;
      first.sliderCurrentProgress = first.currentSlideTime / slideDuration;

      if(first.currentSlideTime >= slideDuration) {
        first.showNext();
        first.currentSlideTime = 0;
        first.sliderCurrentProgress = 0;
      }
    }, 16,666);

    first.sliderProgressRAF = requestAnimationFrame(first.sliderProgress);
  },

  sliderProgress() {
    first.sliderProgressRAF = requestAnimationFrame(first.sliderProgress);
    slideProgressNode.style.setProperty('--progress', first.sliderCurrentProgress);
  },

  showNext() {
    screenSlideNodes.forEach((screenSlide) => {
      if(screenSlide.classList.contains('show')) {
        screenSlide.classList.remove('show');
        screenSlide.classList.add('hide');

        first.hideTimeout = setTimeout(() => {
          screenSlide.classList.remove('hide');
        }, 590);
      }
    });

    first.currentSlide++;

    if(first.currentSlide >= screenSlideNodes.length) {
      first.currentSlide = 0;
    }

    screenSlideNodes[first.currentSlide].classList.add('show');
  },

  stopSlider() {
    clearInterval(first.sliderInterval);
    cancelAnimationFrame(first.sliderProgressRAF);
  }
};

export default first;