import scrollControll from './common/scripts/scrollControll';
import first from './screens/first/first';
import second from './screens/second/second';

import './index.scss';

scrollControll.init();
first.init();
second.init();

function setSizeVars() {
  document.body.style.setProperty('--innerWidth', window.innerWidth);
  document.body.style.setProperty('--innerHeight', window.innerHeight);
}

setSizeVars();

window.addEventListener('resize', setSizeVars);

setTimeout(() => {
  document.body.classList.remove('disableTransitions');
}, 350);